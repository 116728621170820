import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Empty, Form, Select, Spin, Switch, Input, } from "antd";
import { FormattedMessage } from "react-intl";
import { isGroupConcessionnaire } from "../../utils/helpers";
import { isInteger } from "lodash";

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;
class ContactWhitoutWarranty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedClient: props.clients,
      deviceConcern: false,
    };

  }
  componentDidUpdate = async () => {
    const { displayedClient } = this.state;
    const { clients } = this.props;

    // Update client list when parent component trigger a change event
    if (clients.length !== displayedClient.length) {
      this.setState({ displayedClient: this.props.clients });
    }
  };
  render = () => {
    const { deviceConcern } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      clearFormFields,
      criticites,
      clients,
      defaultValueCriticites,
      loadingClient,
      loadingDevice,
      userRole,
      selectedClientConcessionnaire,
      machines,
      onChangeClients,
      subject,
    } = this.props;
    // console.log(
    //   clients
    //     .filter((c) => c.numeroClientEureka !== null)
    //     .filter((c) => Number.isInteger(c.numeroClientEureka))
    // );
    // console.log(this.props);
    // console.log(subject);
    return (
      <Fragment>
        <span style={{ color: "red", fontSize: "14px" }}>
          {" "}
          <FormattedMessage id="contactWhitoutWarranty.informationForm" />{" "}
        </span>
        {/* Subject detail field */}
        {isGroupConcessionnaire({ userRole }) && (
          /* if concessionnaire, select client */
          <div className="select-warranty-devices">
            <FormItem
              label={
                <FormattedMessage id="contact.form.selectedClientConcessionnaire" />
              }
            >
              {getFieldDecorator("numeroClientEureka")(
                <Select
                  notFoundContent={
                    loadingClient ? (
                      <Spin size="small" />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onChange={onChangeClients}
                  onSelect={() => {
                    clearFormFields(["clefUniciteMachine"], null);
                  }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.props.title
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.props.value
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {clients
                    .filter((c) => c.numeroClientEureka !== null)
                    .map((client) => (
                      <Option
                        key={client.guIdClient}
                        title={client.nomClient}
                        value={client.numeroClientEureka}
                      >
                        {client.nomClient} ({client.numeroClientEureka})
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </div>
        )}
        {deviceConcern == false && (
          <div className="select-warranty-devices">
            {/* Select device */}
            <FormItem
              label={<FormattedMessage id="contact.form.selectDevices" />}
            >
              {getFieldDecorator("clefUniciteMachine", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="contact.form.selectDevicesRequired" />
                    ),
                  },
                ],
              })(
                <Select
                  disabled={
                    isGroupConcessionnaire({ userRole })
                      ? selectedClientConcessionnaire
                        ? false
                        : true
                      : false
                  }
                  notFoundContent={
                    loadingDevice ? (
                      <Spin size="small" />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {machines &&
                    machines.map((machine) => (
                      <Option
                        key={machine.clefUnicite}
                        value={machine.clefUnicite}
                      >
                        {machine.nameDevice
                          ? machine.nameDevice
                          : machine.libelleArticle}{" "}
                        - {machine.numeroLot}{" "}
                        {machine.serialIot == "NA"
                          ? ""
                          : " - " + machine.serialIot}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </div>
        )}

        {deviceConcern == true && subject === "Retrofit" && (
          <div className="select-warranty-devices">
            <div className="select-warranty-devices">
              {/* Afin de traiter le plus rapidement votre demande, merci de préciser les éléments ci-dessous : <br /> */}
              <span className="select-warranty-devices">
                <FormItem label={<FormattedMessage id="contact.form.device.number.retrofit" />}>
                  {getFieldDecorator("deviceNumberRetrofit", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="contact.form.device.number.required" />
                        ),
                      },
                    ],
                  })(<Input />)}
                </FormItem>
              </span>
            </div>
            <div className="select-warranty-devices">
              <span className="select-warranty-devices">
                <FormItem label={<FormattedMessage id="contact.form.device.number.commande.retrofit" />}>
                  {getFieldDecorator("numeroCommandeRetrofit", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="contact.form.device.number.commande.retrofit.required" />
                        ),
                      },
                    ],
                  })(<Input />)}
                </FormItem>
              </span>
            </div>
            <div className="select-warranty-devices">
              <span className="select-warranty-devices">
                <FormItem label={<FormattedMessage id="contact.form.device.configuration.retrofit" />}>
                  {getFieldDecorator("configurationRetrofit", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="contact.form.device.configuration.retrofit.required" />
                        ),
                      },
                    ],
                  })(<TextArea autoSize={{ minRows: 2 }} maxLength={200} />,)}
                </FormItem>
              </span>
            </div>
            <div className="select-warranty-devices">
              <span className="select-warranty-devices">
                <FormItem label={<FormattedMessage id="contact.form.device.configuration.installation.retrofit" />}>
                  {getFieldDecorator("configurationInstallationRetrofit", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="contact.form.device.configuration.installation.retrofit.required" />
                        ),
                      },
                    ],
                  })(<TextArea autoSize={{ minRows: 2 }} maxLength={200} />,)}
                </FormItem>
              </span>
            </div>
          </div>
          // </div>
        )}
        <span className="select-warranty-devices">
          <FormItem>
            <Fragment>
              <FormattedMessage id="contact.form.deviceConcernParts1" />
              <Switch
                defaultChecked={deviceConcern}
                onChange={(value) => {
                  this.setState({ deviceConcern: value });
                }}
                disabled={
                  isGroupConcessionnaire({ userRole })
                    ? selectedClientConcessionnaire
                      ? false
                      : true
                    : false
                }
                className="alert-switch"
              />
              {/* <FormattedMessage id="contact.form.deviceConcernParts2" /> */}
            </Fragment>
          </FormItem>
        </span>
        {/* Select criticité */}
        <div className="select-warranty-devices">
          <FormItem label={<FormattedMessage id="contact.form.criticiteid" />}>
            {getFieldDecorator("criticiteId", {
              initialValue: defaultValueCriticites,
            })(
              <Select>
                {criticites.map((criticite) => (
                  <Option key={criticite.id}>{criticite.name}</Option>
                ))}
              </Select>
            )}
          </FormItem>
          {/* is depanding product? */}
          <FormItem
            label={<FormattedMessage id="contact.form.isproductiondependant" />}
          >
            {getFieldDecorator("isProductionDependante")(<Switch />)}
          </FormItem>
          {/* is vendange? */}
          <FormItem label={<FormattedMessage id="contact.form.isvendange" />}>
            {getFieldDecorator("isVendange")(<Switch />)}
          </FormItem>
        </div>
      </Fragment >
    );
  };
}

ContactWhitoutWarranty.propTypes = {
  clearFormFields: PropTypes.func.isRequired,
  selectedClientConcessionnaire: PropTypes.string,
  clients: PropTypes.array.isRequired,
  criticites: PropTypes.array.isRequired,
  defaultValueCriticites: PropTypes.string.isRequired,
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  loadingClient: PropTypes.bool.isRequired,
  loadingDevice: PropTypes.bool.isRequired,
  machines: PropTypes.array.isRequired,
  onChangeClients: PropTypes.func,
  subject: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  app: { locale },
  signIn: { userRole, userID },
  signIn,
}) => ({
  locale,
  signIn,
  userRole,
  userID,
});

export default connect(mapStateToProps)(ContactWhitoutWarranty);
